import React, { useState } from 'react';

import { Outlet } from "react-router-dom";
import { layoutGenerator } from 'react-break';

import Navbar from './Navigation/Navbar';
import Footer from './Navigation/Footer';
import FooterMobile from './Navigation/FooterMobile';

const layout = layoutGenerator({
    mobile: 0,
    phablet: 576,
    tablet: 768,
    desktop: 992,
  });
  
  const OnAtMostPhablet = layout.isAtMost('phablet');
  const OnAtLeastTablet = layout.isAtLeast('tablet');

function Layout(props) {
    const [soundOn, setSoundOn] = useState(false);

    const manageSound = () => { setSoundOn(soundOn => !soundOn) };

    return (
        <>
            <Navbar handleSound={ manageSound } hasSound={soundOn} ></Navbar>

            <div className='body'>
                <Outlet context={[soundOn]} />
            </div>

            <OnAtMostPhablet>
                <FooterMobile />
            </OnAtMostPhablet>
            <OnAtLeastTablet>
                <Footer />
            </OnAtLeastTablet>
        </>
    );
}

export default Layout;