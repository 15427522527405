import React from 'react';

import Section from './Section/Section';
import SpGallery from './SpGallery';

import Affiche2023 from './medias/affiche_bal_2023.jpeg'
import Affiche2023Preview from './medias/affiche_bal_2023_preview.jpg'
import Affiche from './Affiche';

import video from './medias/plaza_palavas_2022.mp4';
import VideoSection from './Video/VideoSection';

const imagesRatios2022 = {
    '_DSC0978.jpg' : { width: 3, height: 2},
    '_DSC0993.jpg' : { width: 3, height: 2},
    '_DSC0986.jpg' : { width: 3, height: 2},
    '_DSC1001.jpg' : { width: 3, height: 2},
    '_DSC1002.jpg' : { width: 3, height: 2},
    '_PIX1623.jpg' : { width: 3, height: 2},
    '_PIX1628.jpg' : { width: 250, height: 221},
    '_PIX1652.jpg' : { width: 3, height: 2},
    '_PIX1642.jpg' : { width: 3, height: 2},
    '_PIX1644.jpg' : { width: 2, height: 3},
    '_PIX1659.jpg' : { width: 3, height: 2},
    '_PIX1661.jpg' : { width: 3, height: 2},
    '_PIX1685.jpg' : { width: 3, height: 2},
    '_PIX1671.jpg' : { width: 447, height: 500},
    '_PIX1675.jpg' : { width: 3, height: 2},
    '_PIX1813.jpg' : { width: 3, height: 2},
    '_PIX1822.jpg' : { width: 3, height: 2},
    '_PIX1827.jpg' : { width: 1, height: 1},
    '_PIX1852.jpg' : { width: 1, height: 1},
    '_PIX1857.jpg' : { width: 3, height: 2},
    '_PIX1859.jpg' : { width: 3, height: 2},
    '_PIX1861.jpg' : { width: 3, height: 2},
    '_PIX1877.jpg' : { width: 80, height: 53},
    '_PIX1862.jpg' : { width: 1, height: 1},
    '_PIX1865.jpg' : { width: 3, height: 2},
    '_PIX1872.jpg' : { width: 3, height: 2},
    '_PIX1879.jpg' : { width: 3, height: 2},
    '_PIX1884.jpg' : { width: 1, height: 1},
    '_PIX1890.jpg' : { width: 3, height: 2},
    '_PIX1894.jpg' : { width: 3, height: 2},
    '_PIX1925.jpg' : { width: 3, height: 2},
    '_PIX1930.jpg' : { width: 2, height: 3},
    '_PIX1939.jpg' : { width: 3, height: 2},
    '_PIX1937.jpg' : { width: 3, height: 2},
    '_PIX1938.jpg' : { width: 3, height: 2},
    '_PIX2069.jpg' : { width: 3, height: 2},
    '_PIX2112.jpg' : { width: 100, height: 111},
    '_PIX2131.jpg' : { width: 3, height: 2},
    '_PIX2132.jpg' : { width: 3, height: 2},
    '_PIX2133.jpg' : { width: 3, height: 2},
    '_PIX2153.jpg' : { width: 3, height: 2},
    '_PIX2155.jpg' : { width: 3, height: 2},
    '_PIX2191.jpg' : { width: 3, height: 2},
    '_PIX2201.jpg' : { width: 3, height: 2},
    '_PIX2204.jpg' : { width: 3, height: 2}
};

function Page2023(props) {
    function importAll(r, ratios) {
        return r.keys().map(r).map(item => {
            let imageSplit = item.replace('/static/media/', '').split(".");
            let imageName = imageSplit[0]+'.'+imageSplit[2];
            
            if(ratios[imageName] === undefined) {
            return { src: item, width:100, height:100 }
            }

            return {
            src: item,
            width: ratios[imageName].width,
            height: ratios[imageName].height
            }
        });
    }

    const imagesThumb = importAll(require.context('./medias/bal2022/thumbs', false, /\.(png|jpe?g|svg)$/), imagesRatios2022);
    const images = importAll(require.context('./medias/bal2022', false, /\.(png|jpe?g|svg)$/), imagesRatios2022);
      
    return (
        <>
            <VideoSection video={video}/>

            <Section id="medias" title="Galerie Photo 2022">
                <SpGallery ratios={imagesRatios2022} images={ images } imagesThumb={ imagesThumb } />
            </Section>
        </>
    )
}

export default Page2023;