import './Footer.scss';

import LogoAmicaleBlancPng from '../medias/aspp-logo-blanc.png';
import { GrFacebook, GrInstagram } from 'react-icons/gr';

import { HashLink as Link } from 'react-router-hash-link';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Mentions from '../Legal/Mentions';
import Cgv from '../Legal/Cgv';

function Footer(props) {
  const MySwal = withReactContent(Swal)

  const showLegalMentions = () => {
    MySwal.fire({
      title: '<strong>Mentions Légales</strong>',
      grow: 'fullscreen',
      html: <Mentions />,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#BFADA3'
    });
  };

  const showCgv = () => {
    MySwal.fire({
      title: '<strong>Conditions Générales de Vente</strong>',
      grow: 'fullscreen',
      html: <Cgv />,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#BFADA3'
    });
  };


  return (
    <>
    <footer className='py-4'>
      <div className='footer-body row justify-content-center align-items-start w-85 w-lg-70 m-auto'>
        <div className='col-6 col-md-3 ps-0'>
          <img className='mw-100 w-70' alt="logo-amicale-palavas" src={ LogoAmicaleBlancPng } />
        </div>
        <div className='col-6 col-md-3'>
          <ul>
            <li><Link to='/#tarifs'>Billeterie</Link></li>
            <li><Link to='/#services'>Découvrir nos Services</Link></li>
            <li><Link to='/#partners'>Découvrir nos Partenaires</Link></li>
          </ul>
        </div>
        <div className='col-6 col-md-3'>
          <ul>
            <li><a href='mailto:aspp34250@gmail.com'>Nous contacter</a></li>
            <li><a href='#mentions-legales' onClick={showLegalMentions}>Mentions Légales</a></li>
            <li><a href='#cgv' onClick={showCgv}>Conditions Générales de Vente</a></li>
          </ul>
        </div>
        <div className='col-6 col-md-3 pe-0'>
          <ul>
            <li>Amicale des Sapeurs Pompiers <br/> de Palavas-les-Flots</li>
            <li>1 Rue de la Tramontane</li>
            <li>34250 Palavas-les-Flots</li>
            <li>
              <a href="https://www.facebook.com/ASPPalavas" target="_blank" rel="noreferrer"><GrFacebook size={30} color='white' className='me-4'/></a>
              <a href="https://www.instagram.com/sapeurs_pompiers_palavas" target="_blank" rel="noreferrer"><GrInstagram size={30} color='white'/></a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
    <p className='w-70 m-auto text-sand font-14 my-2 footer-copy'>&copy; Amicale des Sapeurs Pompiers de Palavas - 2024 - Réalisation Jérémy Fabre</p>
    </>
  );
}

export default Footer;
