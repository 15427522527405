import './Partners.scss';

import React from 'react';

import Slider from "react-slick";

import MairiePalavas from './medias/partenaires/blason_palavas.jpeg'
import Getdown from './medias/partenaires/dj-getdown.png'
// import Mujolan from './medias/partenaires/domaine-mujolan.jpeg'
import ChezLaurent from './medias/partenaires/chez_laurent.png'
import MasDesArmes from './medias/partenaires/mas_des_armes.jpeg'
import Sariviere from './medias/partenaires/sariviere.png'
// import FromageriePalavasienne from './medias/partenaires/fromagerie-palavasienne.jpeg'
// import GorgeFraiche from './medias/partenaires/la-gorge-fraiche.png'
// import PonceParillade from './medias/partenaires/la-parillade.jpeg'
// import RescueOcean from './medias/partenaires/rescue-ocean.webp'

function Partners(props) {
  const partners = [
    { src: MairiePalavas, alt: '', url: 'https://www.palavaslesflots.com', orientation: 'portrait'},
    { src: Getdown, alt: '', url: 'https://www.djgetdown.com', orientation: 'landscape'},
    { src: ChezLaurent, alt: '', url: 'https://www.commerces-palavaslesflots.fr/chezlaurent', orientation: 'landscape'},
    { src: MasDesArmes, alt: '', url: 'https://www.lemasdesarmes.com', orientation: 'landscape'},
    { src: Sariviere, alt: '', url: 'http://www.sariviere.fr', orientation: 'landscape'},

    // { src: Mujolan, alt: '', url: 'https://www.domaine-mujolan.com'},
    // { src: FromageriePalavasienne, alt: '', url: 'https://www.facebook.com/Lafromageriepalavasienne'},
    // { src: GorgeFraiche, alt: '', url: 'https://www.lagorgefraiche.fr'},
    // { src: RescueOcean, alt: '', url: 'https://projectrescueocean.org'},
  ];

  var settings = {
    className: "slider variable-width",
    dots: false,
    autoplay: true,
    arrows: true,
    infinite: true,
    variableWidth: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Slider {...settings} className='my-3 overflow-hidden'>
      { 
        partners.map(
          (item, key) => (
            <div key={key}>
              {  
                (item.orientation === 'landscape')
                ? <a href={ item.url } target='_blank' rel="noreferrer"><img src={ item.src } alt={item.alt} className='mx-5' style={{ maxWidth: '250px', height: 'auto' }}/></a>
                : <a href={ item.url } target='_blank' rel="noreferrer"><img src={ item.src } alt={item.alt} className='mx-5' style={{ maxHeight: '150px' }}/></a>
              }
            </div>
          )
        )
      }
    </Slider>
  );
}

export default Partners;
