import './Navbar.scss';

import { Link } from "react-router-dom";

import LogoAmicaleBleuPng from '../medias/aspp-logo-bleu.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faVolumeUp, faVolumeOff  } from '@fortawesome/free-solid-svg-icons'


function Navbar(props) {
  return (
    <nav className='navbar navbar-expand-lg aspp-header px-2 px-md-5 py-0'>
      <div className='row w-md-100'>
        <div className="col-12 col-md-7">
          <Link to="/" className="d-flex flex-row align-items-center">
            <img className="p-3 aspp-logo" src={ LogoAmicaleBleuPng } alt=""/>
            <div className='logo-name'>
              <h1>Amicale des Sapeurs-Pompiers</h1>
              <h2 className="m-0">Palavas-les-Flots</h2>
            </div>
          </Link>
        </div>
        <div className="col-12 col-md-5 align-self-end">
            <div className="d-flex flex-row align-items-end justify-content-between justify-content-md-end">
              <div className="dropdown">
                <button className="btn bg-blue-pala btn-mw-50 me-5 rounded-0 rounded-top">Archives</button>
                <div className="dropdown-content">
                  <Link to="/bal-2023">Bal 2023</Link>
                  <Link to="/bal-2022">Bal 2022</Link>
                </div>
              </div>
                <div className='me-5'>
                  <a href="https://www.facebook.com/ASPPalavas" className="btn bg-blue-pala btn-mw-50 me-2 rounded-0 rounded-top" target="_blank">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a href="https://www.instagram.com/sapeurs_pompiers_palavas/" className="btn bg-blue-pala btn-mw-50 rounded-0 rounded-top" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
                <div className='volume-box text-center'>
                  <button href="#" onClick={props.handleSound} className="btn bg-danger text-white rounded-0 rounded-top">
                  {
                    props.hasSound
                    ? <FontAwesomeIcon icon={faVolumeUp} />
                    : <FontAwesomeIcon icon={faVolumeOff} />
                  }
                  </button>
                </div>
            </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
