import React from 'react';
import './Decompte.scss';

import Countdown from 'react-countdown';
import DecompteItem from './DecompteItem';

import 'moment/locale/fr';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

function Decompte(props) {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <>
        <DecompteItem time={0} />
        <DecompteItem time={0} />
        <DecompteItem time={0} />
        <DecompteItem time={0} />
      </>;
    } else {
      // Render a countdown
      return <>
        <DecompteItem time={days} label="Jours"/>
        <DecompteItem time={hours} label="Heures"/>
        <DecompteItem time={minutes} label="Minutes"/>
        <DecompteItem time={seconds} label="Secondes"/>
        <div className='video-cta align-self-start'>
          <Link className='btn d-block' to='/#tarifs'>Réserver</Link>
        </div>
      </>;
    }
  };

  return (
    <div className='decompte-container'>
      <div className='d-flex justify-content-between align-items-center'>
        <Countdown date={moment(props.date, "YYYYMMDDHHIISS").toDate()} renderer={renderer} />
      </div>
    </div>
  );
}

export default Decompte;
