import './Services.scss';

import React, { useState } from 'react';
import ServicesItem from './ServicesItem';
import Carousel, { Modal, ModalGateway } from 'react-images';

import menuImageJpg from './medias/menu_bal_2024.jpeg';

function Services(props) {
  const [menuViewer, setMenuViewer] = useState(false);
  const menuImage = [{ source: menuImageJpg }];

  const displayGetdown = () => {
    window.open('https://www.djgetdown.com/', '_blank');
  }

  const displayMenu = () => {
    setMenuViewer(true);
  }

  const closeLightbox = () => {
    setMenuViewer(false);
  };

  return (
    <>
      <div className='services row justify-content-center align-tems-stretch'>
        <ServicesItem title={'Restauration & Boissons'} title2={'Sur Place'} cta={'Voir le Menu'} ctaCallback={displayMenu}/>
        <ServicesItem title={'Orchestre'} title2={'The Sonotown'} />
        <ServicesItem title={'Guest'} title2={'DJ Getdown'} cta={'Voir le Site'} ctaCallback={displayGetdown}/>
      </div>
      <ModalGateway>
          {menuViewer ? (
          <Modal onClose={closeLightbox}>
              <Carousel
                  views={menuImage}
              />
          </Modal>
          ) : null}
      </ModalGateway>
    </>
    
  );
}

export default Services;
