import React from 'react';

import Section from './Section/Section';
import SpGallery from './SpGallery';

import Affiche2023 from './medias/affiche_bal_2023.jpeg'
import Affiche2023Preview from './medias/affiche_bal_2023_preview.jpg'
import Affiche from './Affiche';

import video from './medias/plaza_palavas_2023.m4v';
import VideoSection from './Video/VideoSection';

const imagesRatios2023 = {
    'PIX_7473.jpg' : { width: 2, height: 3 },
    'PIX_7719.jpg' : { width: 3, height: 2 },
    'PIX_7916.jpg' : { width: 3, height: 2 },
    'PIX_8089.jpg' : { width: 3, height: 2 },
    'PIX_8216.jpg' : { width: 3, height: 2 },
    'PIX_8256.jpg' : { width: 2, height: 3 },
    'PIX_8294.jpg' : { width: 3, height: 2 },
    'PIX_8297.jpg' : { width: 2, height: 3 },
    'PIX_8302.jpg' : { width: 2, height: 3 },
    'PIX_8322.jpg' : { width: 2, height: 3 },
    'PIX_8330.jpg' : { width: 3, height: 2 },
    'PIX_8341.jpg' : { width: 3, height: 2 },
    'PIX_8342.jpg' : { width: 3, height: 2 },
    'PIX_8349.jpg' : { width: 3, height: 2 },
    'PIX_8350.jpg' : { width: 3, height: 2 },
    'PIX_8359.jpg' : { width: 3, height: 2 },
    'PIX_8361.jpg' : { width: 3, height: 2 },
    'PIX_8381.jpg' : { width: 3, height: 2 },
    'PIX_8385.jpg' : { width: 3, height: 2 },
    'PIX_8395.jpg' : { width: 3, height: 2 },
    'PIX_8397.jpg' : { width: 3, height: 2 },
    'PIX_8409.jpg' : { width: 3, height: 2 },
    'PIX_8410.jpg' : { width: 3, height: 2 },
    'PIX_8416.jpg' : { width: 3, height: 2 },
    'PIX_8421.jpg' : { width: 3, height: 2 },
    'PIX_8433.jpg' : { width: 3, height: 2 },
    'PIX_8439.jpg' : { width: 3, height: 2 },
    'PIX_8440.jpg' : { width: 3, height: 2 },
    'PIX_8457.jpg' : { width: 3, height: 2 },
    'PIX_8459.jpg' : { width: 3, height: 2 },
    'PIX_8464.jpg' : { width: 3, height: 2 },
    'PIX_8499.jpg' : { width: 3, height: 2 },
    'PIX_8501.jpg' : { width: 3, height: 2 },
    'PIX_8502.jpg' : { width: 3, height: 2 },
    'PIX_8504.jpg' : { width: 3, height: 2 },
    'PIX_8510.jpg' : { width: 3, height: 2 },
    'PIX_8519.jpg' : { width: 3, height: 2 },
    'PIX_8529.jpg' : { width: 3, height: 2 },
    'PIX_8536.jpg' : { width: 3, height: 2 },
    'PIX_8537.jpg' : { width: 3, height: 2 },
    'PIX_8541.jpg' : { width: 3, height: 2 },
    'PIX_8543.jpg' : { width: 3, height: 2 },
    'PIX_8544.jpg' : { width: 3, height: 2 },
    'PIX_8549.jpg' : { width: 3, height: 2 },
    'PIX_8560.jpg' : { width: 3, height: 2 },
    'PIX_8563.jpg' : { width: 3, height: 2 },
    'PIX_8566.jpg' : { width: 2, height: 3 },
    'PIX_8571.jpg' : { width: 3, height: 2 },
    'PIX_8575.jpg' : { width: 3, height: 2 },
    'PIX_8579.jpg' : { width: 3, height: 2 },
    'PIX_8587.jpg' : { width: 3, height: 2 },
    'PIX_8605.jpg' : { width: 3, height: 2 },
    'PIX_8618.jpg' : { width: 3, height: 2 },
    'PIX_8629.jpg' : { width: 3, height: 2 },
    'PIX_8649.jpg' : { width: 3, height: 2 },
    'PIX_8650.jpg' : { width: 3, height: 2 },
    'PIX_8676.jpg' : { width: 3, height: 2 },
    'PIX_8681.jpg' : { width: 3, height: 2 },
    'PIX_8693.jpg' : { width: 2, height: 3 },
    'PIX_8698.jpg' : { width: 3, height: 2 },
    'PIX_8724.jpg' : { width: 3, height: 2 }
  };

function Page2023(props) {
    function importAll(r, ratios) {
        return r.keys().map(r).map(item => {
            let imageSplit = item.replace('/static/media/', '').split(".");
            let imageName = imageSplit[0]+'.'+imageSplit[2];
            
            if(ratios[imageName] === undefined) {
            return { src: item, width:100, height:100 }
            }

            return {
            src: item,
            width: ratios[imageName].width,
            height: ratios[imageName].height
            }
        });
    }

    const imagesThumb = importAll(require.context('./medias/bal2023/thumbs', false, /\.(png|jpe?g|svg)$/), imagesRatios2023);
    const images = importAll(require.context('./medias/bal2023', false, /\.(png|jpe?g|svg)$/), imagesRatios2023);
      
    return (
        <>
            <VideoSection video={video}/>

            <Affiche preview={Affiche2023Preview} affiche={Affiche2023} year={2023}/>

            <Section id="medias" title="Galerie Photo 2023">
                <SpGallery ratios={imagesRatios2023} images={ images } imagesThumb={ imagesThumb } />
            </Section>
        </>
    )
}

export default Page2023;