import React from "react";

function Cgv(props) {
    return (
        <div class='w-85 w-lg-50 m-auto legal-mentions my-5 font-16'>
            <h3>Objet</h3>
            <p>
                Les présentes conditions générales de vente régissent la vente en ligne de billets pour le bal organisé par l'Amicale des Pompiers via la plateforme de paiement sécurisée HelloAsso.<br/>
                L'accès au bal est réservé aux personnes majeures et aux mineurs accompagnés d'un adulte responsable.
            </p>

            <h3>Prix et paiement</h3>
            <p>Le prix des billets est indiqué sur le site web. Le paiement s'effectue en ligne via la plateforme HelloAsso. Les tarifs sont indiqués en euros et toutes taxes comprises*.</p>
            <p className="mt-0 text-gray font-14">* Tarif ne comprenant pas la caution de l'Eco-Cup</p>

            <h3>Confirmation de la commande</h3>
            <p>La confirmation de la commande se fait via un e-mail envoyé par HelloAsso. L'Amicale des Pompiers n'est pas responsable des éventuels problèmes liés à la confirmation de la commande.</p>

            <h3>Annulation et remboursement</h3>
            <p>En cas d'annulation du bal par l'Amicale des Pompiers, les billets seront intégralement remboursés.<br/> En cas d'annulation d'un billet par le client, le remboursement sera effectué uniquement en cas de demande faite au moins 7 jours avant la date du bal.<br/> <span className="font-bold" >Passé ce délai, aucun remboursement ne sera possible.</span></p>

            <h3>Utilisation des billets</h3>
            <p>Les billets sont nominatifs et non remboursables. Ils doivent être présentés lors de l'entrée au bal. L'Amicale des Pompiers se réserve le droit de refuser l'accès au bal à toute personne ne présentant pas de billet valide et/ou ne pouvant prouver sa majorité à l'aide d'une carte d'identité valide.<br/> L'Amicale se réserve également le droit de refuser l'entrée à toute personne en état d’ébriété et sans une tenue correcte.<br/> Les mineurs doivent être accompagnés d'un adulte responsable et une pièce d'identité valide sera exigée pour tous les participants.</p>

            <h3>Interdiction d'entrée aux mineurs non-accompagnés</h3>
            <p>L'accès au bal est interdit aux mineurs non accompagnés d'un adulte responsable. Une vérification de la carte d'identité sera effectuée à l'entrée. <br/> Toute personne ne pouvant prouver son âge ou accompagnée d'un adulte non responsable se verra refuser l'entrée au bal et aucune demande de remboursement ne sera possible.</p>

            <h3>Responsabilité</h3>
            <p>L'Amicale des Pompiers ne peut être tenue responsable des éventuels problèmes liés à la vente en ligne de billets, mais s'engage à collaborer avec HelloAsso pour résoudre tout problème éventuel.<br/> En cas de problème lors de l'entrée au bal, l'Amicale des Pompiers ne pourra être tenue responsable.</p>

            <h3>Propriété intellectuelle</h3>
            <p>Les billets sont la propriété de l'Amicale des Pompiers.<br/> Toute utilisation, reproduction ou représentation, intégrale ou partielle, des billets, sur quelque support que ce soit, est interdite et constitue une contrefaçon, sauf autorisation préalable de l'Amicale des Pompiers.</p>

            <h3>Droit applicable et juridiction compétente</h3>
            <p>Les présentes conditions générales de vente sont soumises au droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>

            <p>En cochant la case "J'accepte les conditions générales de vente" lors de l'achat des billets en ligne, le client reconnaît avoir pris connaissance et accepté l'ensemble des présentes conditions générales de vente.</p>
        </div>
    )
}

export default Cgv;