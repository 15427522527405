import './BilletLine.scss';

import React from 'react';

function BilletLine(props) {
  var moment = require('moment-timezone');

  let date = moment().tz("Europe/Paris");
  let date2 = moment('2024-06-29 16:00:00').tz("Europe/Paris");

  return (
    <div className="card rounded-3 billet-line h-100 mb-4">
      <div className="card-body p-3">
        <div className='d-flex'>
          <div className='w-100'>
            <h5 className="card-title font-16 fw-600">{ props.title }</h5>
            <div className='d-flex justify-content-start align-items-center font-12 w-100 text-grey'>
              <span className='me-3'>{ props.text1 }</span>
              <span className='me-3'>{ props.text2 }</span>
              <span>{ props.mention }</span>
            </div>
          </div>
          <div className='booking d-flex align-items-center justify-content-between'>
            <span className="card-text card-price font-34 mx-3">{ props.price }</span>
            {
              date < date2
              ? <button onClick={(e) => { props.ctaAction(); return false}} target="_blank" rel="noreferrer" className="btn btn-sm bg-blue-pala h-30p rounded-4">{ props.cta }</button>
              : <span className='warning strong text-center d-block'>Réservation sur place</span>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BilletLine;
