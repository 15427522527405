import './Homepage.scss';

import { useOutletContext } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBeer, faChampagneGlasses, faChildReaching, faMartiniGlass, faPlus, faUserGroup, faWineBottle } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'

import Video from './Video/Video';
import Section from './Section/Section';
import BilletCard from './BilletCard';
import Partners from './Partners';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import HelloAsso from './Legal/HelloAsso';
import Affiche from './Affiche';

//import video from 'medias/plaza_palavas_2023.m4v'
import video from './medias/trailer_plaza_palavas_2024_sd.m4v';
import areneZone from './medias/arenes_zones.jpg';
import BilletLine from './BilletLine';
import SpGallery from './SpGallery';
import { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import Affiche2024 from './medias/affiche_bal_2024.jpg'
import Affiche2024Preview from './medias/affiche_bal_2024_preview.jpg'
import Services from './Services';

const imagesRatios2023 = {
  'PIX_7473.jpg' : { width: 2, height: 3 },
  'PIX_7719.jpg' : { width: 3, height: 2 },
  'PIX_7916.jpg' : { width: 3, height: 2 },
  'PIX_8089.jpg' : { width: 3, height: 2 },
  'PIX_8216.jpg' : { width: 3, height: 2 },
  'PIX_8256.jpg' : { width: 2, height: 3 },
  'PIX_8294.jpg' : { width: 3, height: 2 },
  'PIX_8297.jpg' : { width: 2, height: 3 },
  'PIX_8302.jpg' : { width: 2, height: 3 },
  'PIX_8322.jpg' : { width: 2, height: 3 },
  'PIX_8330.jpg' : { width: 3, height: 2 },
  'PIX_8341.jpg' : { width: 3, height: 2 },
  'PIX_8342.jpg' : { width: 3, height: 2 },
  'PIX_8349.jpg' : { width: 3, height: 2 },
  'PIX_8350.jpg' : { width: 3, height: 2 },
  'PIX_8359.jpg' : { width: 3, height: 2 },
  'PIX_8361.jpg' : { width: 3, height: 2 },
  'PIX_8381.jpg' : { width: 3, height: 2 },
  'PIX_8385.jpg' : { width: 3, height: 2 },
  'PIX_8395.jpg' : { width: 3, height: 2 },
  'PIX_8397.jpg' : { width: 3, height: 2 },
  'PIX_8409.jpg' : { width: 3, height: 2 },
  'PIX_8410.jpg' : { width: 3, height: 2 },
  'PIX_8416.jpg' : { width: 3, height: 2 },
  'PIX_8421.jpg' : { width: 3, height: 2 },
  'PIX_8433.jpg' : { width: 3, height: 2 },
  'PIX_8439.jpg' : { width: 3, height: 2 },
  'PIX_8440.jpg' : { width: 3, height: 2 },
  'PIX_8457.jpg' : { width: 3, height: 2 },
  'PIX_8459.jpg' : { width: 3, height: 2 },
  'PIX_8464.jpg' : { width: 3, height: 2 },
  'PIX_8499.jpg' : { width: 3, height: 2 },
  'PIX_8501.jpg' : { width: 3, height: 2 },
  'PIX_8502.jpg' : { width: 3, height: 2 },
  'PIX_8504.jpg' : { width: 3, height: 2 },
  'PIX_8510.jpg' : { width: 3, height: 2 },
  'PIX_8519.jpg' : { width: 3, height: 2 },
  'PIX_8529.jpg' : { width: 3, height: 2 },
  'PIX_8536.jpg' : { width: 3, height: 2 },
  'PIX_8537.jpg' : { width: 3, height: 2 },
  'PIX_8541.jpg' : { width: 3, height: 2 },
  'PIX_8543.jpg' : { width: 3, height: 2 },
  'PIX_8544.jpg' : { width: 3, height: 2 },
  'PIX_8549.jpg' : { width: 3, height: 2 },
  'PIX_8560.jpg' : { width: 3, height: 2 },
  'PIX_8563.jpg' : { width: 3, height: 2 },
  'PIX_8566.jpg' : { width: 2, height: 3 },
  'PIX_8571.jpg' : { width: 3, height: 2 },
  'PIX_8575.jpg' : { width: 3, height: 2 },
  'PIX_8579.jpg' : { width: 3, height: 2 },
  'PIX_8587.jpg' : { width: 3, height: 2 },
  'PIX_8605.jpg' : { width: 3, height: 2 },
  'PIX_8618.jpg' : { width: 3, height: 2 },
  'PIX_8629.jpg' : { width: 3, height: 2 },
  'PIX_8649.jpg' : { width: 3, height: 2 },
  'PIX_8650.jpg' : { width: 3, height: 2 },
  'PIX_8676.jpg' : { width: 3, height: 2 },
  'PIX_8681.jpg' : { width: 3, height: 2 },
  'PIX_8693.jpg' : { width: 2, height: 3 },
  'PIX_8698.jpg' : { width: 3, height: 2 },
  'PIX_8724.jpg' : { width: 3, height: 2 }
};

function Homepage(props) {
  const MySwal = withReactContent(Swal);

  const [modalImage, setModalImage] = useState([]);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [soundOn] = useOutletContext();
  
  const handleAreneClick = () => {
    setModalImage([{source: areneZone}]);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  function importAll(r, ratios) {
    return r.keys().map(r).map(item => {
      let imageSplit = item.replace('/static/media/', '').split(".");
      let imageName = imageSplit[0]+'.'+imageSplit[2];
      
      if(ratios[imageName] === undefined) {
        return { src: item, width:100, height:100 }
      }

      return {
        src: item,
        width: ratios[imageName].width,
        height: ratios[imageName].height
      }
    });
  }

  const imagesThumb = importAll(require.context('./medias/bal2023/thumbs', false, /\.(png|jpe?g|svg)$/), imagesRatios2023);
  const images = importAll(require.context('./medias/bal2023', false, /\.(png|jpe?g|svg)$/), imagesRatios2023);

  const handleBooking = () => {
    MySwal.fire({
      title: 'Information',
      grow: 'fullscreen',
      html: <HelloAsso />,
      showCloseButton: false,
      showCancelButton: true,
      confirmButtonText: 'Vers la réservation',
      cancelButtonText:'X',
      confirmButtonColor: '#A93226'
    }).then((result) => {
      if (result.isConfirmed) {
        window.open("https://www.helloasso.com/associations/amicale-sapeurs-pompiers-palavas/evenements/plaza-des-pompiers-de-palavas-2024", '_blank').focus();
      }
    });
  };

  return (
    <>
      <Video soundOn={soundOn} video={video}/>
      
      <Affiche preview={Affiche2024Preview} affiche={Affiche2024} year={2024}/>

      <Section id="tarifs" title="Billetterie" subtitle={
      <>
      La billetterie est proposée et sécurisée via notre partenaire HelloAsso.<br/>Il est possible d'effectuer un don au profit de l'Amicale des Pompiers de Palavas au cours de votre réservation<br/>
      <span className='warning my-4 strong d-block'>Fermeture de la Billeterie en ligne Vendredi 28 Juin à 16H00. <br/> Vente sur place à partir de 19H.</span>
      </>
      } className='w-100 parallax'>
        <div className='g-3'>
          <div className='row d-flex justify-content-center align-items-start'>
            <div className='col-12 col-lg-4'>
              <div className='w-80 w-md-60 w-lg-85 position-relative m-auto m-lg-0 mb-4 mb-lg-0' onClick={handleAreneClick}>
                <img src={areneZone} className='rounded-3 mw-100 arena-image' alt="Zones Réservation Arènes"/>
                <div className='arena-areas-mention d-flex position-absolute bottom-0 text-center text-white text-uppercase fw-800 w-30 m-auto justify-content-between align-items-center'>
                    <span className='d-block'>Agrandir les zones</span>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </div>
            <div className='col'>
              <BilletLine 
                title={"Tarif Réduit"} 
                text1={<><FontAwesomeIcon icon={faClock} className='me-2'/>Entrée entre 19h et 20h30</>}//a mettre en gras
                text2={<><FontAwesomeIcon icon={faMartiniGlass} className='me-2'/>Une consommation offerte *</>}
                mention={<><FontAwesomeIcon icon={faChildReaching} className='me-2'/>Gratuit pour les enfants de - 12 ans</>}
                price={"5€"}
                cta={"Réserver"}
                ctaAction={handleBooking}
              />
              <BilletLine
                title={"Tarif Classique"}
                text1={<><FontAwesomeIcon icon={faClock} className='me-2'/>Entrée entre 20h30 et 23h10</>}
                text2={<><FontAwesomeIcon icon={faMartiniGlass} className='me-2'/>Une consommation offerte *</>}
                mention={<><FontAwesomeIcon icon={faChildReaching} className='me-2'/>Gratuit pour les enfants de - 12 ans</>}
                price={"10€"}
                cta={"Réserver"}
                ctaAction={handleBooking}
              />
              
              <div className='d-flex justify-content-between row align-items-stretch'>
                <div className='col-12 col-md-4 mb-4 mb-md-0'>
                  <BilletCard 
                    title={"Tonneau & Bière"}
                    subtitle={<><FontAwesomeIcon icon={faUserGroup} className='me-2'/>4 personnes</>}
                    text1={<><FontAwesomeIcon icon={faBeer} className='me-2'/>Un pichet de Bière inclus *</>}
                    text2={<><FontAwesomeIcon icon={faChildReaching} className='me-2'/>Gratuit pour les enfants de - 12 ans</>}
                    price={"50€"}
                    cta={"Réserver"}
                    ctaAction={handleBooking}
                    className={'col-3 '}
                  />
                </div>
                <div className='col-12 col-md-4 mb-4 mb-md-0'>
                  <BilletCard
                    title={"Tonneau & Vin"}
                    subtitle={<><FontAwesomeIcon icon={faUserGroup} className='me-2'/>4 personnes</>}
                    text1={<><FontAwesomeIcon icon={faWineBottle} className='me-2'/>Une bouteille de Vin inclus *</>}
                    text2={<><FontAwesomeIcon icon={faChildReaching} className='me-2'/>Gratuit pour les enfants de - 12 ans</>}
                    price={"60€"}
                    cta={"Réserver"}
                    ctaAction={handleBooking}
                  />
                </div>
                <div className='col-12 col-md-4 mb-4 mb-md-0'>
                  <BilletCard 
                    title={"Tonneau & Champagne"}
                    subtitle={<><FontAwesomeIcon icon={faUserGroup} className='me-2'/>4 personnes</>}
                    text1={<><FontAwesomeIcon icon={faChampagneGlasses} className='me-2'/>Une bouteille de Champagne inclus *</>}
                    text2={<><FontAwesomeIcon icon={faChildReaching} className='me-2'/>Gratuit pour les enfants de - 12 ans</>}
                    price={"80€"}
                    cta={"Réserver"}
                    ctaAction={handleBooking}
                  />
                </div>
              </div>
              <div className='mention'>
                <p className=''>* Tarif ne comprenant pas la caution de l'Eco-Cup</p>
              </div>
            </div>
          </div>
        </div>
        
      </Section>

      <Section id="services" title="Découvrir les Services" className='py-5'>
        <Services />
        {/* <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-3 g-3">
          <div className="col">
            <div className="card h-100">
            <img src={ EquipePlaceholder } className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Bar & Restauration</h5>
                <p className="card-text fst-italic font-14">
                  Notre équipe se tient à votre disposition toute la soirée au bar de la Plaza pour vous rafraichir (Vin, Bière, Soft...)<br/><br/>
                  Restauration sucrée et salée sur place
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
            <img src={ MusicPlaceholder } className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Animations</h5>
                <p className="card-text fst-italic font-14">Toute la soirée vous pourrez danser, sauter, enflammer le sol des Arènes avec, l'orchestre The Sonotowon, et en guest <a href="https://youtu.be/miDQf0uZ29E?t=42" className="text-gold font-bold" target="_blank" rel="noreferrer">DJ Getdown...</a></p>
              </div>
            </div>
          </div>
        </div> */}
      </Section>

      <Section id="partners" title="Découvrir nos Partenaires" className="w-100">
        <Partners />
      </Section>

      <Section id="medias" title="Galerie Photo 2023">
          <SpGallery ratios={imagesRatios2023} images={images} imagesThumb={imagesThumb} />
      </Section>

      <ModalGateway>
          {viewerIsOpen && modalImage.length ? (
          <Modal onClose={closeLightbox}>
              <Carousel
                  views={modalImage}
              />
          </Modal>
          ) : null}
      </ModalGateway>
    </>
  );
}

export default Homepage;
