
import React from "react";

function Mentions() {
    return (
        <div class='w-85 w-lg-50 m-auto legal-mentions my-5 font-16'>
            <p><b>Nom de l'association :</b> Amicale des Pompiers de Palavas-les-Flots</p><br/>

            <p><b>Adresse :</b> 1 Rue de la Tramontane, 34250 Palavas-les-Flots</p><br/>

            <p><b>Numéro de téléphone :</b> -</p><br/>

            <p><b>Adresse e-mail :</b> aspp34250@gmail.com</p><br/>

            <p><b>Directeur de la publication :</b> Président de l'amicale Adrien Acier</p><br/>

            <p><b>Hébergeur du site web :</b> AWS France</p><br/>

            <p><b>Propriété intellectuelle :</b><br/> L'ensemble des contenus présents sur le site web (textes, images, logos, etc.) sont la propriété exclusive de l'Amicale des Pompiers de Palavas-les-Flots ou de ses partenaires, sauf mention contraire.<br/> Toute utilisation, reproduction ou représentation, intégrale ou partielle, des contenus du site, sur quelque support que ce soit, est interdite et constitue une contrefaçon, sauf autorisation préalable de l'Amicale des Pompiers de Palavas-les-Flots.</p><br/>

            <p><b>Données personnelles :</b><br/> Les données personnelles collectées sur le site web sont uniquement destinées à l'Amicale des Pompiers de Palavas-les-Flots et ne seront en aucun cas cédées à des tiers.<br/> Conformément à la loi Informatique et Libertés du 6 janvier 1978, vous disposez d'un droit d'accès, de rectification et de suppression des données vous concernant. Vous pouvez exercer ces droits en contactant l'Amicale des Pompiers de Palavas-les-Flots à l'adresse indiquée ci-dessus.</p><br/>

            <p><b>Cookies :</b><br/> Le site web utilise des cookies pour améliorer l'expérience utilisateur et réaliser des statistiques de visite. Vous pouvez vous opposer à l'utilisation de ces cookies en paramétrant votre navigateur internet.</p><br/>

            <p><b>Vente en ligne de billets pour le bal via HelloAsso :</b><br/> La vente en ligne de billets pour le bal est gérée par HelloAsso, une plateforme de paiement sécurisée.<br/> L'Amicale des Pompiers de Palavas-les-Flots ne peut être tenue responsable des éventuels problèmes liés à la vente en ligne de billets, mais s'engage à collaborer avec HelloAsso pour résoudre tout problème éventuel.</p><br/>

            <p><b>Limitation de responsabilité :</b><br/> L'Amicale des Pompiers de Palavas-les-Flots ne peut être tenue responsable des dommages directs ou indirects résultant de l'utilisation du site web, ou de l'impossibilité d'y accéder.<br/> Les liens hypertextes présents sur le site web peuvent renvoyer vers des sites externes dont l'Amicale des Pompiers de Palavas-les-Flots n'a pas le contrôle et pour lesquels elle décline toute responsabilité.</p><br/>
            
            <p><b>Droit applicable et juridiction compétente :</b><br/> Les présentes mentions légales sont soumises au droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>
        </div>
    );
}

export default Mentions;