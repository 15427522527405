import './ServicesItem.scss';

import React from 'react';

function ServicesItem(props) {
  
  return (
    <div className='services-item col-12 col-md-6 col-lg-4 p-2'>
      <div className='services-item-cover'>
        <div className='services-item-title position-relative h-100 d-flex align-items-center justify-content-center'>
          <div className='text-center'>
            <h3>{props.title}</h3>
            <h4>{props.title2}</h4>
          </div>
        </div>
        <div className='services-cta text-center'>
          {
            props.cta
            ? <button className='btn btn-xs bg-white rounded-0' onClick={props.ctaCallback ? props.ctaCallback : null}>{ props.cta }</button>
            : <button className='btn btn-xs bg-white rounded-0 opacity-0'>Test</button>
          }
        </div>
      </div>
    </div>
  );
}

export default ServicesItem;
