import React from 'react';
import './DecompteItem.scss';

function DecompteItem(props) {
  return (
    <div className='time-square text-center d-flex flex-column align-items-center justify-content-center'>
      <div className='time'>{ (props.time > 9) ? props.time : '0'+props.time  }</div>
      <div className='label'>{ props.label }</div>
    </div>
  );
}

export default DecompteItem;
