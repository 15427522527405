import './Video.scss';

import ReactPlayer from 'react-player'
import { HashLink as Link } from 'react-router-hash-link';
import Decompte from '../Decompte/Decompte';

//import video from '../medias/bal_sp_palavas.mp4'
//import video from '../medias/plaza_palavas_2022.mp4'

function Video(props) {

  return (
    <div id="video" className='video-container mb-4'>
      <ReactPlayer className="video-player fullscreen" loop url={ props.video } playing={ true } playsinline={ true } controls={ false } muted={ !props.soundOn } width='100%' height='100%'></ReactPlayer>
      <div className='video-jumbo'>
        <div className='video-title mb-4'>
          <h1>Plaza des Sapeurs-Pompiers</h1>
          <h2>Samedi 29 Juin 2024 - à partir de 19h</h2>
        </div>
        <Decompte date={"20240629 19:00:00"}/>
      </div>
    </div>
  );
}

export default Video;
