import './BilletCard.scss';

import React from 'react';

function BilletCard(props) {
  var moment = require('moment-timezone');

  let date = moment().tz("Europe/Paris");
  let date2 = moment('2024-06-29 16:00:00').tz("Europe/Paris");

  return (
    <div className="card rounded-3 billet-card border-0 h-100">
      <div className="card-body">
        <h5 className="card-title font-16 fw-600">{ props.title }</h5>
        <h6 className="card-subtitle font-12 fw-600">{ props.subtitle }</h6>
        <p className="card-text card-price mb-0">{ props.price }</p>
        <ul className='card-content font-12 list-none ps-0 text-grey'>
          <li>{ props.text1 }</li>
          <li>{ props.text2 }</li>
        </ul>
      </div>
      <div className='card-footer p-0 border-0'>
        {
          date < date2
          ? <button onClick={(e) => { props.ctaAction(); return false}} target="_blank" rel="noreferrer" className="btn bg-blue-pala rounded-0 rounded-bottom w-100 h-40p">{ props.cta }</button>
          : <span className='warning strong text-center d-block'>Réservation sur place</span>
        }
      </div>
    </div>
  );
}

export default BilletCard;
