import './Affiche.scss';

import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Section from './Section/Section';

import Carousel, { Modal, ModalGateway } from "react-images";

function Affiche(props) {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const images = [{source: props.affiche}];

    const handleAfficheClick = () => {
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    return (
        <>
            <Section id="affiche-2024" className='row'>
                <div className='col-md-6 d-flex align-items-center justify-content-center justify-content-md-end pe-md-5' onClick={handleAfficheClick}>
                    <div className='affiche-container'>
                        <div className='overlay'>
                            <div className='affiche-title w-100 text-center text-white fw-800'>
                                <span className='d-block'>Agrandir l'affiche</span>
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </div>
                        <img src={props.affiche} className='m-auto' />
                    </div>
                </div>
                <div className='col ps-md-5 mt-4 mt-md-0 d-flex align-items-center'>
                    <div className=''>
                        <h2 className='text-blue-pala fw-800 text-uppercase'>Affiche Plaza {props.year}</h2>
                        <h3 className='text-blue-pala fw-800 text-uppercase'>Sapeurs-Pompiers Palavas-les-Flots</h3>
                    </div>
                </div>
            </Section>
            <ModalGateway>
                {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel
                        views={images}
                    />
                </Modal>
                ) : null}
            </ModalGateway>
        </>
        

    )
}

export default Affiche;