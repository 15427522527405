import './Section.scss';

function Section(props) {
  return (
    <div id={ props.id } className={`container-fluid p-5 section `+props.className}>
        {
          props.title
          ? <h3 className='section-title text-grey m-0 mb-5 p-2'>{ props.title }</h3>
          : null
        }
        
        {
          props.subtitle &&
          <p className='section-subtitle font-14 fst-italic text-center mb-3'>
            { props.subtitle }
          </p>
        }
        
        { props.children }
    </div>
  );
}

export default Section;
