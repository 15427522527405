import './VideoSection.scss';

import ReactPlayer from 'react-player'
import Decompte from '../Decompte/Decompte';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

//import video from '../medias/bal_sp_palavas.mp4'
//import video from '../medias/plaza_palavas_2022.mp4'

function VideoSection(props) {
    const [playVideo, setPlayVideo] = useState(false);
    
    const handleVideoPlay = () => {
        setPlayVideo(true);
    };

    return (
    <div className='video-section row'>
        <div className='col-12 m-auto p-0'>
            <div className='video-section-container'>
                <ReactPlayer className="video-section-player" loop url={ props.video } playing={ playVideo } playsinline={ true } controls={ true } width='100%' height='100%'></ReactPlayer>
                {
                    !playVideo
                    ?   <div onClick={handleVideoPlay} className='overlay d-flex align-items-center justify-content-center'>
                            <FontAwesomeIcon icon={faPlayCircle} className='video-section-play text-white'/>
                        </div>
                    : null
                }
                
            </div>
        </div>
    </div>
    );
}

export default VideoSection;
