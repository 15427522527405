import './HelloAsso.scss';

import React from "react";

import DemoSuppressionGif from '../medias/demo_suppression_frais.gif'

function HelloAsso(props) {

    return (
        <div className="row">
            <div className="col">
                <img src={DemoSuppressionGif} alt="demo suppression frais HelloAsso" />
            </div>
            <div className="col p-5">
                <p>Notre partenaire de paiement applique automatiquement des frais de gestion à l'étape <span className="font-bold">"Récapitulatif"</span> de votre réservation</p>
                <p className="font-bold">Il vous est possible de modifier ou supprimer ces frais qui ne sont pas obligatoires</p>
            </div>
        </div>
    )
}

export default HelloAsso;